<template>
    <div class="card-interaes-container">
        <img :alt="titulo" :src="image_src" class="card-interaes-image" />
        <div class="card-interaes-container1">
            <h3 class="card-interaes-text">{{ titulo }}</h3>
            <div class="card-interaes-text1">{{ descricao }}</div>
            <v-btn color="#bed600ff" class="card-interaes-button BotaoGradiente button thqLink" @click="rota()">
                <svg viewBox="0 0 1024 1024" class="card-interaes-icon">
                    <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
                </svg> 
                {{$t_('selecione')}}
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardInteraes',
    props: {
        descricao: {
            type: String,
            default: 'Text',
        },
        idapresentacao: {
            type: String,
            default: '',
        },
        titulo: {
            type: String,
            default: 'Ingressos',
        },
        image_src: {
            type: String,
            default: '42f3fc4a-9621-4b67-96c3-ac5663da87bf',
        },
    },
    methods: {
        rota(){
            let link = this.$superingresso.tratarLink(this.titulo);
            this.$router.push({ name: 'Produto', params: { id: link, idapresentacao: this.idapresentacao}})
        },
        
    },
   
  }
</script>

<style scoped>

.card-interaes-container {
  width: 300px;
  height: 340px;
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}
.card-interaes-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.card-interaes-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 15px;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.card-interaes-text {
  color: var(--dl-color-principal);
  align-self: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.card-interaes-text1 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}
.card-interaes-button {
  color: var(--dl-color-gray-white);
  height: 42px;
  display: flex;
  align-self: center;
  margin-top: 15px;
  border-width: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  text-decoration: none;
}
.card-interaes-icon {
  fill: var(--dl-color-gray-white);
  width: 22px;
  height: 21px;
  align-self: center;
}
.rootClassName {
  flex: 1;
}
.rootClassName1 {
  flex: 1;
}
.rootClassName2 {
  flex: 1;
}
.rootClassName3 {
  flex: 1;
}
.rootClassName4 {
  flex: 1;
}
.rootClassName5 {
  flex: 1;
}
</style>
